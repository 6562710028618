import { Pipe, PipeTransform } from '@angular/core';
import { LookupModel } from '@siren-survey/app/models/shared/lookup.model';
import { AuthenticationService } from '../../auth/authentication.service';
import { ProgramService } from './program.service';
import { ProgramActivityExecutionDisplay } from 'src/app/models/admin/program/activities/activity-execution';
import { Observable, map, of } from 'rxjs';
import { ProgramOrganization } from 'src/app/models/admin/program/program/program';

@Pipe({
  name: 'isOrganizationEnabled'
})
export class IsOrganizationEnabledPipe implements PipeTransform {

  userActiveOrganizations: LookupModel[] = [];
  constructor(private _authService: AuthenticationService, private _programService: ProgramService) {

  }

  transform(activityAttendees: ProgramActivityExecutionDisplay[], programOrganization: ProgramOrganizationIds): Observable<boolean> {
    this.userActiveOrganizations = this._authService.getSessionService().getActiveSession().getActiveOrganizations();

    if (!this.userActiveOrganizations || programOrganization == null || programOrganization.organizationId == null || programOrganization.organizationId <= 0) {
      return of(false);
    }

    let hasAccess: boolean = false;
    for (let model of this.userActiveOrganizations) {
      if (model?.id != undefined && !hasAccess) {
        hasAccess = model.id == programOrganization.organizationId;
      }
    }

    if (programOrganization?.programOrganizations) {
      programOrganization.programOrganizations.forEach(org => {
        if (org.organization.id == programOrganization.organizationId) {
          hasAccess = org.programBeneficiaries.length > 0 ? false : true;
        }
      });
    }

    return of(!hasAccess);
  }

}

export interface ProgramOrganizationIds {
  organizationId: number;
  programOrganizations: ProgramOrganization[];
}
