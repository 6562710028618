<form [formGroup]="form" (ngSubmit)="saveProgram()">
  <div class="program-form container">
    <app-title [title]="'program.form-title' | translate" [goBackCallback]="goBack"></app-title>

    <div class="container mat-focus-indicator program-form-container">
      <div class="program-form-content">
        <!-- PROGRAM HEADER INFORMATION -->
        <div class="program-form-container-header program-title-container">
          <div class="full-width-element">
            <mat-form-field class="full-width-element" >
              <mat-label class="">{{'program.fields.name' | translate}}</mat-label>
              <input matInput formControlName="name">
            </mat-form-field>
          </div>
          <div class="program-form-action-btn-container align-right">
            <span class=" color-slide-toogle margin-start-20">
              <span *ngIf="form.get('isDeleted').value"
                class=" slide-toogle-label">{{'program.fields.disabled' |
                translate}}</span>
              <span *ngIf="!form.get('isDeleted').value"
                class=" slide-toogle-label-danger">{{'program.fields.not-disabled' |
                translate}}</span>
            </span>
            <button *ngIf='this.isEditMode && this.canModifyProgram' type="submit" class="btn btn-submit">
              <span class="button-label">{{'global.save' | translate}}</span>
            </button>
          </div>
        </div>

        <!-- PROGRAM MAIN INFORMATION -->
        <div class="row program-information-container">
          <div class="col-lg-3 col-sm-6 col-12">
            <march-datepicker [max]="this.form?.get('endDate')?.getRawValue()"
              [title]="'program.fields.start-date' | translate"
              [dateFormControl]="this.form?.get('startDate')"></march-datepicker>
          </div>
          <div class="col-lg-3 col-sm-6 col-12">
            <march-datepicker [min]="this.form?.get('startDate')?.getRawValue()"
              [title]="'program.fields.end-date' | translate"
              [dateFormControl]="this.form?.get('endDate')"></march-datepicker>
          </div>
          <div class="col-12 mt-2">
            <mat-form-field class="full-width-element">
              <mat-label class="">{{'program.fields.goal' | translate}}</mat-label>
              <textarea matInput type="text-area" autocomplete="off" min="0" class=""
                formControlName="goal"></textarea>
            </mat-form-field>
          </div>
          <!-- PROGRAM TAGS -->
          <div class="col-12">
            <mat-form-field  class="chip-list full-width-element">
              <mat-label>{{'program.fields.tags' | translate}}</mat-label>
              <mat-chip-grid #chipGrid aria-label="Tag selection">
                <mat-chip-row *ngFor="let selectedTag of selectedTags" (removed)="removeTag(selectedTag)">
                  {{selectedTag.tag.value}}
                  <button matChipRemove [attr.aria-label]="'remove ' + selectedTag">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-grid>
              <input placeholder="{{'program.fields.new-tag' | translate}}" #tagInput [formControl]="tagCtrl"
                [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                />
				<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
                <mat-option *ngFor="let filteredTag of filteredTags | async" [value]="filteredTag">
                  {{filteredTag.tag.value}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-checkbox *ngFor="let organization of organizations" (click)="$event.stopPropagation()"
              (change)="handleOrganizationSelection($event, organization)"
              [disabled]="activityAttendees | isOrganizationEnabled: {programOrganizations: this.program?.programOrganizations ,organizationId: organization?.id} | async" [checked]="organization | isOrganizationSelected: this.concernedOrganization "
              class="checkbox-container">
              {{ organization?.value }}
            </mat-checkbox>
          </div>
        </div>

        <!-- PROGRAM RELATED LISTS -->
        <div class="row panel-title" *ngIf="program && program.id && program.id > 0">
          <div class="profile-action-buttons">
            <button class="btn btn-expand" type="button" mat-button (click)="toogleAccordions('open',$event);">{{
              'global.expand-all' | translate }}</button>
            <button class="btn btn-collapse" type="button" mat-button (click)="toogleAccordions('close',$event);">{{
              'global.collapse-all' | translate }}</button>
          </div>

          <mat-accordion class="profile-headers-align" multi>
            <app-survey-link-selection #programSurveyLink *ngIf="program.id" [isEditable]="this.isEditMode" [program]="this.program" [linkTo]="'PROGRAM'"  [customClass]="'program-survey-link'"></app-survey-link-selection>
            <!-- PROGRAM ACTIVITIES LIST -->
            <mat-expansion-panel [expanded]="false" (opened)="true" #activityPanel
              class="remove-mat-expansion-panel-padding activity-execution-mat-expansion-panel">
              <mat-expansion-panel-header class="expansion-panel-header">
                <mat-panel-title class="panel-title">
                  <div class="expansion-header-container">
                    <div class="header-location">
                      <span>{{'program.activities.title' | translate}}  : {{programActivities.length}}</span>
                    </div>
                    <button *ngIf="this.isEditMode && this.canModifyProgram" title="{{ 'program.buttons.add-activity' | translate }}"
                      class="btn" type="button" mat-button
                      (click)="addProgramActivity(); $event.stopPropagation();">
                      <span class="fa fa-plus"></span>
                    </button>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="mat-expansion-container">
                <ng-template matExpansionPanelContent formArrayName="programActivities">
                  <mat-accordion multi>
                    <mat-expansion-panel [expanded]="false"
                      *ngFor="let progActivity of programActivities.controls; let i = index;" [formGroupName]="i"
                       style="box-shadow: none;">
                      <mat-expansion-panel-header class="activity-execution-panel-header">
                        <mat-panel-title class="full-width-element">
                          <div class="flex-row-container full-width-element">
                            <div class="row prog-act-row">
                              <div class="col-lg-4 col-sm-6 col-12 activity-elem-no-padding tooltip-input-container "
                                (click)="$event.stopPropagation();">
                                <mat-form-field
                                  class="full-width-element mat-input-with-no-space col-lg-4 col-sm-6 col-12">
                                  <mat-label class="">{{'program.fields.activity' |
                                    translate}}</mat-label>
                                  <mat-select class="" formControlName="activity"
                                    [compareWith]="objectComparisonFunction">
                                    <mat-option *ngFor="let activity of activities" [value]="activity">
                                      {{ activity.value }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>

                              <div class="col-lg-3 col-sm-6 col-12">
                                <march-datepicker [max]="progActivity?.get('toDate')?.getRawValue()" [title]="'program.fields.from' | translate"
                                  [dateFormControl]="progActivity?.get('fromDate')"></march-datepicker>
                              </div>

                              <div class="col-lg-3 col-sm-6 col-12">
                                <march-datepicker [min]="progActivity?.get('fromDate')?.getRawValue()" [title]="'program.fields.to' | translate"
                                  [dateFormControl]="progActivity?.get('toDate')"></march-datepicker>
                              </div>
                              <div
                                class="col-lg-2 col-sm-6 col-12 activity-field tooltip-input-container activity-elem-no-padding"
                                (click)="$event.stopPropagation();">
                                <mat-form-field class="full-width-element mat-input-with-no-space">
                                  <mat-label class="">{{'program.fields.occurrence' |
                                    translate}}</mat-label>
                                  <input matInput type="number" min="0" (keydown)="onKeyDownEvent($event)"
                                    class=""
                                    placeholder="{{'program.fields.occurrence' | translate}}"
                                    formControlName="occurrence">
                                </mat-form-field>
                                <app-info-tooltip
                                  message="{{'program.fields-tooltip.occurrence' | translate}}"></app-info-tooltip>
                              </div>
                            </div>
                            <button *ngIf="this.isEditMode && this.canModifyProgram" style="width: auto;" title="{{ 'program.buttons.remove-activity' | translate }}"
                              type="button" class="activity-flat-button"
                              (click)="removeProgramActivity(i); $event.stopPropagation();">
                              <img src="assets/icons/delete-icon.svg" style="width: 20px;">
                            </button>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-survey-link-selection #activitySurveyLink *ngIf="program.id" [isEditable]="this.isEditMode" [programId]="id" [program]="this.program" [programActivityId]="progActivity?.get('id')?.getRawValue()" [linkTo]="'ACTIVITY'" ></app-survey-link-selection>
                      <!-- ACTIVITY EXECUTIONS -->
                      <div class="full-width-element expansion-pannel-bottom-border" style="text-align: center;">
                        <app-activity-execution-list [programActivityId]="progActivity?.get('id')?.getRawValue()"
                          [isEditable]="isEditable" [programId]="id"
                          [minDate]="progActivity?.get('fromDate')?.getRawValue()"
                          [maxDate]="progActivity?.get('toDate')?.getRawValue()"
                          [programOrganization]="getConcerenedProgramOrganization()"
                          [tags]="tags"
                          [activityOccurrence]="getProgramActivityOriginalOccurrence(progActivity?.get('id')?.getRawValue())"
                          (activityAttendees)="getActivityAttendees($event)">
                        </app-activity-execution-list>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-template>
                <div *ngIf="this.programActivities == undefined || this.programActivities?.length == 0">
                  <span class="empty-list-label">{{ 'program.fields.no-enrolled-program-activities' | translate }}</span>
                </div>
              </div>
            </mat-expansion-panel>

            <!-- PROGRAM ORGANIZATIONS LIST -->
            <ng-container *ngFor="let programOrganizationFg of programOrganizations.controls; let i = index;">
                <app-program-organization-form
                  [title]="programOrganizationFg?.get('title').value"
                  [programOrganization]="getProgramOrganizationByOrgId(programOrganizationFg?.get('organization')?.getRawValue()?.id)"
                  [programOrganizationFormGroup]="programOrganizationFg"
                  [organizationId]="programOrganizationFg?.get('organization')?.getRawValue()?.id"
                  [isEditable]="this.isEditMode && this.canModifyProgram"
                  [hasAccessToOrg]="hasAccessToOrganization(programOrganizationFg?.get('organization')?.getRawValue()?.id)"
                  [hasUnsavedBeneficiariesCount]="hasUnsavedBeneficiariesCount"
                  [hasUnsavedClassesCount]="hasUnsavedClassesCount" [classes]="classes" [program]="program">
                </app-program-organization-form>
            </ng-container>


          </mat-accordion>
        </div>

        <!-- DISPLAY SAVE PROGRAM FIRST MESSAGE -->
        <div class="row" style="padding-bottom: 20px;" *ngIf="!id">
          <h5 style="text-align: center;">{{ 'program.message.create-program-needed' | translate}}</h5>
        </div>
        <div style="text-align: center;padding-bottom: 20px;" *ngIf="id && !(program && program.id && program.id > 0)">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</form>
<app-loading-spinner *ngIf="loading"></app-loading-spinner>
