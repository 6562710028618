import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { MenuActionObject, MenuActionEmittedObject } from '@siren-survey/app/models/shared/datatable-objects.model';
import { DataTableModel } from '@siren-survey/app/models/shared/cell-data-model';
import { ResponsiveListInlineEditObject, TableAction } from '@siren-survey/app/models/shared/table-action';
import { TableFilterTypeEnum, TableFilterElement, FilterOperator, TableFilterOutput } from '@siren-survey/app/models/shared/table-filter.model';
import { LookupService } from '@siren-survey/app/services/lookups/lookup.service';
import { DatatableInlineEdit } from '@siren-survey/app/component/shared/datatable/datatable-inline-edit.service';
import { MarchModuleEnum, MarchPermissionEnum } from 'src/app/shared/enum/user-role.enum';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { LookupTypeService } from 'src/app/services/admin/lookup-type.service';
import { LookUpList } from '../../../../models/admin/program/lookupType/lookupList';
import { LookUpType } from 'src/app/models/admin/program/lookupType/LookUpType';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from 'src/app/services/shared/validators.service';

@Component({
  selector: 'app-lookups-admin-table',
  templateUrl: './lookups-admin-table.component.html',
  styleUrls: ['./lookups-admin-table.component.sass']
})
export class LookupsAdminTableComponent extends DatatableInlineEdit implements OnInit, OnDestroy {
  tableTitleLabel: string;

  lastCreatedId = 0;

  reloadEventSubject: Subject<boolean> = new Subject<boolean>();
  inlineEditEventSubject: Subject<ResponsiveListInlineEditObject> = new Subject<ResponsiveListInlineEditObject>();

  filterEventSubject: Subject<Map<string, any>> = new Subject<Map<string, any>>();
  initiateSearchFromFilter = true;
  canAdd: boolean = false;
  hasListAccess: boolean = false;
  predefinedFilter: Map<string, any>;
  marchPermissionEnum: typeof MarchPermissionEnum = MarchPermissionEnum;
  marchModuleEnum: typeof MarchModuleEnum = MarchModuleEnum;

  selectedService: any;
  lookupList: LookUpList;
  lookupSelected: boolean = false;
  form: FormGroup;
  addBtnLabel: string;

  private _subscription = new Subscription();

  _booleanIconColumnDisplayList: string[] = [
    "deprecated"
  ]

  _tableColumns: DataTableModel<LookUpType>[] = [
    { columnDef: 'key', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "lookup-type.fields.key", header: this._translateService.instant('lookup-type.fields.key'), cell: (element: LookUpType) => `${element?.key}`, width: undefined, contentClass: 'leftAlign' },
    { columnDef: 'valueEn', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "lookup-type.fields.english-value", header: this._translateService.instant('lookup-type.fields.english-value'), cell: (element: LookUpType) => `${element?.valueEn}`, width: undefined, contentClass: 'leftAlign' },
    { columnDef: 'valueAr', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "lookup-type.fields.arabic-value", header: this._translateService.instant('lookup-type.fields.arabic-value'), cell: (element: LookUpType) => `${element?.valueAr}`, width: undefined, contentClass: 'leftAlign' },
    { columnDef: 'deprecated', type: TableFilterTypeEnum.Boolean, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "lookup-type.fields.deprecated", header: this._translateService.instant('lookup-type.fields.deprecated'), cell: (element: LookUpType) => `${element?.deprecated ? element?.deprecated : false}`, width: undefined, contentClass: 'leftAlign' },
    { columnDef: '_menu_inline', type: TableFilterTypeEnum.String, lookupName: undefined, lookupsValueById: undefined, headerTranslationPath: "", header: '', cell: undefined, width: '25px' }
  ];

  filters: TableFilterElement[] = [
  ];

  _tableMenuAction: MenuActionObject[] = [
    { name: this._translateService.instant('global.edit'), actionName: "EDIT_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "assets/icons/duplicate-icon-black.svg", allowedAction: this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_LOOKUPS), accessRightExpressions: new Map<string, string>([["id", "id > 0"], ["key", "key != 'RISKASSESSMENT' && key !='EVALUATION'"]]) },
    { name: this._translateService.instant('global.deprecate'), actionName: "DELETE_ACTION", iconStyle: "width: 20px; height: 20px; margin-right: 5px; margin-left: 5px;", iconUrl: "../../../../assets/icons/delete-icon.svg", allowedAction: this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_LOOKUP), accessRightExpressions: new Map<string, string>([["deprecated", "deprecated == undefined || deprecated == false"], ["id", "id > 0"], ["key", "key != 'RISKASSESSMENT' && key !='EVALUATION'"]]) }
  ]

  _sortingDataAccessor: (data: any, sortHeaderId: string) => string | number;
  _filteringPredicate: (data: any, filter: string) => boolean;

  getDeleteBtnLabel(): string {
    return this._translateService.instant('global.delete');
  }

  constructor(
    public service: LookupTypeService,
    private _router: Router,
    private _route: ActivatedRoute,
    private readonly _translateService: TranslateService,
    private _lookupService: LookupService,
    private _snackBar: MatSnackBar,
    private _authService: AuthenticationService) {
    super();

    this.form = new FormGroup({
      'lookup': new FormControl({ value: undefined, disabled: false }),
    });

    this.predefinedFilter = new Map<string, any>();
    this._sortingDataAccessor = (item: any, property: string) => {
      switch (property) {
        default: return item[property];
      }
    };

    this._filteringPredicate = (data: any, filter: string) => {
      const accumulator = (currentTerm: any, key: any) => {
        if (key && key == 'values') {
          let calculatedValue = "";
          for (let element of data[key]) calculatedValue += element.value;
          return currentTerm + calculatedValue;
        } else {
          return currentTerm + data[key];
        }
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };


    this.canAdd = this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.SAVE_LOOKUPS);
    this.hasListAccess = this._authService.isModuleAccessible(this.marchModuleEnum.LOOKUPS_MGT.module);

  }

  ngOnInit(): void {
    this._subscription.add(
      this._route.data.subscribe((params) => {
        this.lookupList = params.data;
      })
    );
  }

  lookupChanged(event: any) {

    this.lookupSelected = true;
    this.service.setServiceUrl("/v1/admin/lookups/paged/" + event.value.key);
    this.service.setServiceCreationUrl("/v1/admin/lookups/" + event.value.key);
    this.reloadEventSubject.next(true);
  }


  usePostForUpdate() {
    return true;
  }
  // this is used to route the table row onto its form page with the id attached
  updateViewMode(event: TableAction) {
    if (event.mode == TableAction.CREATE_MODE) {
      this.lastCreatedId--;
      let editFormGroup = new FormGroup({
        'id': new FormControl({ value: this.lastCreatedId, disabled: true }),
        'key': new FormControl({ value: '', disabled: false }, [Validators.required, CustomValidators.noWhitespaceValidator]),
        'deprecated': new FormControl({ value: false, disabled: false }, [Validators.required]),
        'valueEn': new FormControl({ value: '', disabled: false }, [Validators.required, CustomValidators.noWhitespaceValidator]),
        'valueAr': new FormControl({ value: '', disabled: false }, [Validators.required, CustomValidators.noWhitespaceValidator]),
      })
      let inlineEditObject = new ResponsiveListInlineEditObject(this.lastCreatedId, this.lastCreatedId, editFormGroup, false);
      this.inlineEditEventSubject.next(inlineEditObject);
    }
  }

  applyFilter(event: TableFilterOutput) {
    if (event) {
      let filterMap: Map<string, any> = new Map();
      event.filterComponents.forEach(filter => {
        filterMap.set(filter.name, filter.value);
      });
      this.filterEventSubject.next(filterMap);
    }
  }


  tableMenuActionHandler(menuAction: MenuActionEmittedObject) {
    if (menuAction && menuAction.actionName && menuAction.object) {

      if (menuAction.actionName == "DELETE_ACTION") {
        if (this._authService.userModuleAccessRightValidation(this.marchPermissionEnum.DELETE_LOOKUP)) {
          this.service.deleteObject(menuAction.object.id).subscribe(response => {
            this.reloadEventSubject.next(true);
          }, error => {
            let message = error && error.error && error.error.message.includes("ConstraintViolationException")
              ? this._translateService.instant('error.record-depending-error')
              : this._translateService.instant('error.record-error');
            this._snackBar.open(message, null, { duration: 3000 });
          });
        }
      } else {
        this.handleInlineEditingRequests(menuAction, this._snackBar, this._translateService);
      }
    }
  }

  handleOnSubmitError() {
    return this._translateService.instant('lookup-type.fields.error-message');
  }

  isInlineEditable(): boolean {
    return true;
  }

  getTableFormGroupModel(): FormGroup<any> {
    return new FormGroup({
      'id': new FormControl({ value: undefined, disabled: true }),
      'key': new FormControl({ value: undefined, disabled: false }, [Validators.required, CustomValidators.noWhitespaceValidator]),
      'deprecated': new FormControl({ value: false, disabled: false }, [Validators.required]),
      'valueEn': new FormControl({ value: undefined, disabled: false }, [Validators.required, CustomValidators.noWhitespaceValidator]),
      'valueAr': new FormControl({ value: undefined, disabled: false }, [Validators.required, CustomValidators.noWhitespaceValidator]),
    })
  }

  triggerInlineEditEventSubject(inlineEditObject: ResponsiveListInlineEditObject): void {
    if (this.inlineEditEventSubject) this.inlineEditEventSubject.next(inlineEditObject);
  }

  getTableService() {
    return this.service;
  }

  getValidForm(menuAction: MenuActionEmittedObject): boolean {
    menuAction.form.markAllAsTouched();
    menuAction.form.updateValueAndValidity();
    return menuAction.form.valid;
  }


  formatEditObject(object: any, isAfterSave: boolean): any {
    if (!isAfterSave) {
      let arabicValue = object?.valueAr;
      let englishValue = object?.valueEn;
      return {
        "id": object?.id,
        "key": object?.key,
        "value": object?.englishValue,
        // "order": object?.order,
        "deprecated": object?.deprecated ? object?.deprecated : false,
        "valueEn": englishValue ? englishValue : object.valueEn,
        "valueAr": arabicValue ? arabicValue : object.valueAr
      }
    }
    else {
      let arabicValue = object?.at(0)?.valueAr;
      let englishValue = object?.at(0)?.valueEn;
      return {
        "id": object?.at(0)?.id,
        "key": object?.at(0)?.key,
        "value": englishValue,
        "deprecated": object?.at(0)?.deprecated ? object?.at(0)?.deprecated : false,
        "valueEn": englishValue,
        "valueAr": arabicValue
      }
    }
  }

  formatObject(object: any): any {
    //this is done to trim values entered by user before saving
    //formatObject function is iniated by datatable-inline-edit-service.ts
    object.key = object?.key?.trim();
    object.valueEn = object?.valueEn?.trim();
    object.valueAr = object?.valueAr?.trim();
    return object;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}

